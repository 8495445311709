<template>
  <div class="mainform">
    <div class="mainHeader">
      管理职责评分细项
      <span @click="back" class="back">返回></span>
    </div>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <div class="tableList">
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData">
          <el-table-column
            prop="serialNumber"
            label="序号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="detailName"
            label="评分细项"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="detailTrueScore"
            label="细项基准分"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              {{ scope.row.status == 1 ? "启用" : "停用" }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="blockup(scope.row, false)"
                v-if="scope.row.status == 1 ? true : false"
                >停用</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="blockup(scope.row, true)"
                v-if="scope.row.status == 1 ? false : true"
                >启用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <dialog-box
      ref="addDialog"
      :dialogShow="DialogShow"
      @handleClose="handleClose"
      @confirmAdd="confirmAdd"
      title="新增评分细项"
      componentName="AdddetailDialog"
      :buttonData="[
        { label: '取消', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'confirmAdd', type: 'primary', size: 'mini' },
      ]"
    >
    </dialog-box> -->
    <el-dialog title="新增评分细项" v-model="DialogShow">
      <el-form
        class="content bascform"
        ref="form"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="序号" required prop="serialNumber">
          <el-input v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分细项" required prop="detailName">
          <el-input v-model="form.detailName"></el-input>
        </el-form-item>
        <el-form-item label="细项基准分" required prop="detailTrueScore">
          <el-input v-model="form.detailTrueScore"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <dialog-box    :dialogShow="EditDialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd"  title="编辑评分项目" componentName="EditAddscoreDialog"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box> -->
    <el-dialog title="编辑评分细项" v-model="EditDialogShow">
      <el-form
        class="content bascform"
        ref="form"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="序号" required prop="serialNumber">
          <el-input v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分细项" required prop="detailName">
          <el-input v-model="form.detailName"></el-input>
        </el-form-item>
        <el-form-item label="细项基准分" required prop="detailTrueScore">
          <el-input v-model="form.detailTrueScore"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="confirmchange">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'ScoreDetail',
  data () {
    return {
      form: {
        serialNumber: '',
        detailName: '',
        detailTrueScore: ''
      },
      rules: {
        serialNumber: [
          { required: true, message: '请输入序号', trigger: 'blur' }
        ],
        detailName: [
          { required: true, message: '请输入评分细项', trigger: 'blur' }
        ],
        detailTrueScore: [
          { required: true, message: '请输入细项基准分', trigger: 'blur' }
        ]
      },
      id: '',
      scoreId: '',
      menudata: [
        {
          label: '+新增细项',
          action: 'adddetail',
          id: 1
        }
      ],
      DialogShow: false,
      EditDialogShow: false,
      showFormData: {},
      tableData: []
    }
  },
  created () {
    this.scoreId = this.$route.query.id
  },
  mounted () {
    this.scoreDetail()
  },
  methods: {
    // 编辑时点确定
    confirmchange () {
      const obj = {
        detailName: '',
        detailTrueScore: '',
        scoreId: this.scoreId,
        id: this.id,
        serialNumber: '',
        status: 1
      }
      obj.serialNumber = this.form.serialNumber
      obj.detailName = this.form.detailName
      obj.detailTrueScore = this.form.detailTrueScore
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          request('/api/quality/scoreDetail/save', 'post', obj).then(
            (response) => {
              if (response.code === '200') {
                this.$message({
                  message: '成功',
                  type: 'success'
                })
                this.scoreDetail()
              }
              this.EditDialogShow = false
              // this.DialogShow = true
            }
          )
        }
      })
    },
    buttonClick: function (data) {
      this.form = {
        serialNumber: '',
        detailName: '',
        detailTrueScore: ''
      }
      this.DialogShow = true
    },

    // 停用或启用评分细项
    blockup (data, show) {
      const obj = { ...data }
      //  console.log(obj);
      if (show) {
        obj.status = 1
      } else {
        obj.status = 0
      }
      request('/api/quality/scoreDetail/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          // console.log(res);1
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.scoreDetail()
        }
      })
    },
    edit (data) {
      // console.log(data);
      this.id = data.id
      this.EditDialogShow = true
      this.form = {
        serialNumber: '',
        detailName: '',
        detailTrueScore: ''
      }
    },
    scoreDetail () {
      // 评分细项
      request(
        '/api/quality/scoreDetail/getByScoreId/' + this.scoreId,
        'GET',
        {}
      ).then((res) => {
        console.log(res)
        if (res.code === '200') {
          // console.log(res.data);
          this.tableData = res.data
        }
      })
    },
    handleClose () {
      this.DialogShow = false
      this.EditDialogShow = false
    },
    confirmAdd () {
      // 新增评分细项弹框确认按钮
      const obj = {
        detailName: '',
        detailTrueScore: '',
        scoreId: this.scoreId,
        serialNumber: '',
        status: 1
      }
      obj.serialNumber = this.form.serialNumber
      obj.detailName = this.form.detailName
      obj.detailTrueScore = this.form.detailTrueScore
      // var data = this.$refs.addDialog.$refs.content.form;
      // console.log("777", data);
      // obj.serialNumber = data.serialNumber;
      // obj.detailName = data.detailName;
      // obj.detailTrueScore = data.detailTrueScore;
      // console.log(111,obj);
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          request('/api/quality/scoreDetail/save', 'post', obj).then(
            (response) => {
              if (response.code === '200') {
                this.$message({
                  message: '成功',
                  type: 'success'
                })
                this.scoreDetail()
              }
              this.DialogShow = false
              // this.DialogShow = true
            }
          )
        }
      })
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/tableList.scss";
@import "@/assets/css/elForm.scss";
</style>
